<template>
  <main class="flex bg-transparent relative xl:px-6 lg:px-6 md:px-5 px-3">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main>
        <h1 class="text-xl font-bold mb-2">Family</h1>
        <section
          class="flex xl:flex-row lg:flex-row md:flex-row flex-col items-start"
        >
          <div
            class="
              xl:w-80
              lg:w-80
              md:w-96
              w-full
              h-28
              max-w-md
              text-white
              flex
              items-center
              justify-between
              px-5
              py-6
              bg-primary-color
            "
          >
            <div class="flex items-center flex-shrink-0">
              <img
                src="@/assets/images/users-white.svg"
                alt="total family members"
              />
            </div>
            <div class="w-full ml-6">
              <div class="mb-2 text-sm">Total Family Members</div>
              <div class="flex items-center justify-between w-full">
                <div v-if="familyMembers" class="font-bold">
                  {{ familyMembers.length }}
                </div>
                <button
                  class="
                    text-primary-color
                    bg-white
                    font-semibold
                    text-sm
                    py-1
                    px-3
                    rounded-full
                  "
                  @click="addNewFamilyMember"
                >
                  Add new
                </button>
              </div>
            </div>
          </div>

          <div
            class="
              xl:w-80
              lg:w-80
              md:w-96
              w-full
              h-28
              max-w-md
              flex
              items-center
              justify-between
              px-5
              py-6
              xl:ml-6
              lg:ml-6
              md:ml-6
              ml-0
              xl:mt-0
              lg:mt-0
              md:mt-0
              mt-4
              bg-primary-color bg-opacity-10
            "
          >
            <div class="w-full">
              <div class="mb-2 text-sm">
                Enter the maximum number of family members you want to have
              </div>
              <div class="flex items-center justify-between w-full text-sm">
                <div class="font-bold flex items-center relative">
                  <div class="fonnt-bold mr-4">Family Size</div>
                  <span
                    class="
                      bg-primary-color
                      text-white
                      p-1
                      rounded-md
                      cursor-pointer
                      mr-2
                      shadow-md
                    "
                    :class="{ 'inactive-state': loading }"
                    @click="decreaseFamilySize"
                  >
                    <minus-icon size="1.2x" class="custom-class"></minus-icon>
                  </span>
                  <input
                    v-debounce:1000ms="updateFamily"
                    v-model="familySize"
                    type="text"
                    placeholder="Edit  Family Size"
                    class="
                      border border-border-color
                      dark:text-legend-black
                      rounded-md
                      py-1
                      px-3
                      focus:outline-none
                      w-24
                    "
                  />
                  <span
                    class="
                      bg-primary-color
                      text-white
                      p-1
                      rounded-md
                      cursor-pointer
                      ml-2
                      shadow-md
                    "
                    :class="{ 'inactive-state': loading }"
                    @click="increaseFamilySize"
                  >
                    <plus-icon size="1.2x" class="custom-class"></plus-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="my-12">
          <div class="flex items-center justify-between select-none">
            <h2 class="font-bold">All Members List</h2>
            <!-- <button
              class="py-2 px-6 rounded-md text-white text-sm bg-primary-color"
            >
              Activities
            </button> -->
          </div>
          <LoadingSpinner v-if="!familyMembers" />
          <div
            v-if="familyMembers && familyMembers.length"
            class="w-full overflow-x-scroll"
          >
            <div
              class="
                table
                w-full
                bg-white
                dark:bg-dark-mode-primary dark:text-border-color
                text-sm
                rounded-md
                shadow-lg
                mt-6
              "
            >
              <div class="table-row-group">
                <div class="table-row">
                  <div class="table-cell py-4 px-5 font-semibold text-center">
                    SN
                  </div>
                  <div class="table-cell py-4 px-5 font-semibold">Username</div>
                  <div class="table-cell py-4 px-5 font-semibold capitalize">
                    first Name
                  </div>
                  <div class="table-cell py-4 px-5 font-semibold capitalize">
                    last Name
                  </div>
                  <div class="table-cell py-4 px-5 font-semibold capitalize">
                    Relationship
                  </div>
                  <div
                    class="py-4 px-5 font-semibold capitalize"
                    style="width: 120px"
                  >
                    Email
                  </div>
                  <div class="table-cell py-4 px-5 font-semibold">Status</div>
                  <div class="table-cell py-4 px-5 font-semibold text-center">
                    Action
                  </div>
                </div>
                <div
                  v-for="(member, index) in familyMembers"
                  :key="index"
                  class="
                    data-items
                    table-row
                    text-legend-gray
                    dark:text-border-color
                    dark:border-b
                    dark:border-border-color
                  "
                >
                  <div class="table-cell py-4 px-5 text-center">
                    {{ index + 1 }}
                  </div>
                  <div class="table-cell py-4 px-5">
                    {{ member.user.username }}
                  </div>
                  <div class="table-cell py-4 px-5 capitalize">
                    {{ member.user.first_name }}
                  </div>
                  <div class="table-cell py-4 px-5 capitalize">
                    {{ member.user.last_name }}
                  </div>
                  <div class="table-cell py-4 px-5 capitalize">
                    {{ member.relationship }}
                  </div>
                  <div class="table-cell py-4 px-5">
                    {{ member.user.email }}
                  </div>
                  <div class="table-cell py-4 px-5">
                    <span
                      v-if="member.status"
                      class="
                        text-xs text-secondary-green
                        bg-secondary-green bg-opacity-20
                        inline-block
                        w-16
                        py-2
                        text-center
                        rounded-full
                        font-semibold
                      "
                      >Active</span
                    >
                    <span
                      v-if="!member.status"
                      class="
                        text-xs text-primary-red
                        bg-primary-red bg-opacity-20
                        inline-block
                        w-16
                        py-2
                        text-center
                        rounded-full
                        font-semibold
                      "
                      >Inactive</span
                    >
                  </div>
                  <div class="w-full relative">
                    <more-vertical-icon
                      size="1.5x"
                      class="custom-class block mx-auto table-options"
                      @click="showOptions"
                    ></more-vertical-icon>
                    <ul
                      v-click-outside="hideDropdown"
                      class="
                        table-options
                        options
                        w-24
                        capitalize
                        absolute
                        bg-gray-200
                        rounded-md
                        bottom-0
                        dark:text-legend-gray
                        hidden
                      "
                    >
                      <li
                        class="
                          py-1
                          px-2
                          cursor-pointer
                          hover:bg-gray-300
                          rounded-md
                          flex
                          items-center
                        "
                        @click="editRestriction(member)"
                      >
                        <edit-icon
                          size="1x"
                          class="custom-class mr-1"
                        ></edit-icon>
                        <span>edit</span>
                      </li>
                      <li
                        class="
                          py-1
                          px-2
                          cursor-pointer
                          hover:bg-gray-300
                          rounded-md
                          flex
                          items-center
                        "
                        @click="fundFamilyMember(member)"
                      >
                        <img
                          src="@/assets/images/transaction-icon.svg"
                          alt="transfer funds to family member"
                          class="w-4 mr-1"
                        />
                        transfer
                      </li>
                      <li
                        class="
                          py-1
                          px-2
                          cursor-pointer
                          text-primary-red
                          hover:bg-gray-300
                          rounded-md
                          flex
                          items-center
                        "
                        @click="removeMember(member.id)"
                      >
                        <trash-2-icon
                          size="1x"
                          class="custom-class mr-1"
                        ></trash-2-icon>
                        <span>Remove</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <LoadingSpinner v-if="!transactions" /> -->
              </div>
            </div>
          </div>

          <div
            v-if="familyMembers && !familyMembers.length"
            class="
              xl:w-1/5
              lg:w-2/5
              md:w-2/5
              w-full
              mx-auto
              flex flex-col
              items-center
              justify-center
              mt-20
            "
          >
            <img
              src="@/assets/images/empty-list.svg"
              alt="no family member added yet"
            />
            <p class="text-center my-6">
              You have not added any family member yet
            </p>
            <button
              class="
                text-white text-sm
                bg-primary-color
                rounded-md
                py-2
                px-4
                flex
                items-center
              "
              @click="addingNewMember = true"
            >
              <plus-circle-icon
                size="1.2x"
                class="custom-class mr-2"
              ></plus-circle-icon>
              <span>Add Family</span>
            </button>
          </div>
        </section>
      </main>
    </section>
    <custom-modal v-if="addingNewMember">
      <NewMember
        slot="cart"
        :familyMembers="familyMembers"
        @closeModal="memberAdded"
      />
    </custom-modal>
    <custom-modal v-if="editingRestrictions">
      <EditRestrictions
        slot="cart"
        :selectedMember="selectedMember"
        @closeModal="editingRestrictions = false"
        @member-updated="memberUpdated"
        @merchant-restricted="merchantRestricted"
      />
    </custom-modal>
    <custom-modal v-if="fundingFamilyMember">
      <FundFamilyMember
        slot="cart"
        :beneficiary="beneficiary"
        @closeModal="closeFundFamily"
        @confirmAction="confirmAction"
      />
    </custom-modal>
    <!-- Confirm confirm fund transfer -->
    <section
      v-if="confirmTransaction"
      class="
        w-screen
        h-screen
        bg-legend-black bg-opacity-30
        fixed
        top-0
        left-0
        flex
        items-center
        justify-center
        z-50
      "
    >
      <div
        class="
          w-1/3
          py-12
          px-8
          bg-primary-background
          shadow-lg
          rounded-lg
          text-legend-gray
        "
      >
        <div>
          You are about to make a transfer of
          <span class="text-lg font-semibold"
            >N{{ beneficiary.amount | moneyFormat }}</span
          >
          to
          <span class="text-lg font-semibold capitalize">{{
            beneficiary.full_name
          }}</span>
        </div>
        <div class="my-8 text-xl text-primary-color font-semibold">
          Are You Sure?
        </div>
        <twin-button
          @leftAction="confirmTransaction = false"
          @rightAction="transferFund"
          :loading="submitting"
        >
          <span slot="left-button">No, Cancel</span>
          <span slot="right-button">Yes, Continue</span>
        </twin-button>
      </div>
    </section>
  </main>
</template>

<script>
import EditRestrictions from '@/components/EditRestrictions.vue'
import FundFamilyMember from '@/components/TansferFundsToFamily.vue'
import TwinButton from '@/components/TwinButton.vue'
import ClickOutside from 'vue-click-outside'
import errorModule from '@/util/error.handle'
import stripeTable from '@/util/stripeTable'
import { mapGetters } from 'vuex'

import {
  PlusIcon,
  PlusCircleIcon,
  MoreVerticalIcon,
  MinusIcon,
  Trash2Icon,
  EditIcon,
} from 'vue-feather-icons'
import NewMember from '@/components/AddNewFamilyMember.vue'
export default {
  name: 'FamilyWallet',
  components: {
    PlusIcon,
    PlusCircleIcon,
    NewMember,
    MoreVerticalIcon,
    EditRestrictions,
    FundFamilyMember,
    MinusIcon,
    Trash2Icon,
    EditIcon,
    TwinButton,
  },
  mounted() {
    this.fetchAllFamilyMembers()

    if (this.user && this.user.family) {
      this.familySize = this.user.family.size
    }
  },
  watch: {
    mode() {
      stripeTable('.data-items', this.mode)
    },
  },
  data() {
    return {
      familyMembers: null,
      notification: null,
      beneficiary: null,
      familySize: 0,
      addingNewMember: false,
      transferringFunds: false,
      fundingFamilyMember: false,
      editingRestrictions: false,
      confirmTransaction: false,
      selectedMember: null,
      loading: false,
      submitting: false,
    }
  },
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapGetters(['user', 'mode']),
  },
  methods: {
    async updateFamily() {
      if (this.familySize >= 5) {
        this.notification = {
          type: 'info',
          message: 'You have reached the maximum family size allowed',
        }
        return
      }

      const status = await this.updateFamilySize(this.familySize)

      if (status === 200) {
        this.notification = {
          type: 'success',
          message: 'Family size updated successfully',
        }
      }
    },
    confirmAction(payload) {
      this.fundingFamilyMember = false
      this.confirmTransaction = true
      this.beneficiary = payload
    },
    fundFamilyMember(member) {
      this.beneficiary = member
      this.fundingFamilyMember = true
    },
    merchantRestricted(message) {
      this.editingRestrictions = false
      this.notification = {
        type: 'success',
        message,
      }
    },
    memberUpdated() {
      this.editingRestrictions = false
      this.notification = {
        type: 'success',
        message: 'Member update successful',
      }
    },
    addNewFamilyMember() {
      if (!this.familySize) {
        this.notification = {
          type: 'info',
          message: `<p><strong>Set you family size before adding members.</strong></p>
          `,
        }
        return
      } else if (
        this.familyMembers &&
        this.familyMembers.length === this.familySize
      ) {
        this.notification = {
          type: 'info',
          message: `<p><strong>You have reached maximum family size</strong></p>
          <p>Increase your family size to add new member.</p>
          `,
        }
        return
      } else {
        this.addingNewMember = true
      }
    },
    memberAdded() {
      this.addingNewMember = false
      this.fetchAllFamilyMembers()
    },
    async increaseFamilySize() {
      if (this.familySize === 4) {
        this.notification = {
          type: 'info',
          message: 'You have reached the maximum family size allowed',
        }
        return
      }

      const status = await this.updateFamilySize(this.familySize + 1)

      if (status === 200) {
        this.familySize++
      }
    },
    async decreaseFamilySize() {
      if (this.familySize === 1) {
        return
      }

      if (this.familySize === this.familyMembers.length) {
        this.notification = {
          type: 'info',
          message:
            "Family size can't be less than current number of family members",
        }
        return
      }

      const status = await this.updateFamilySize(this.familySize - 1)

      if (status === 200) {
        this.familySize--
      }
    },
    hideDropdown(event) {
      const classList = Array.from(event.target.classList)

      if (!classList.includes('table-options')) {
        this.closeAllDropdown()
      }
    },
    showOptions(event) {
      let evt = event.target
      let nextElement

      this.closeAllDropdown()

      if (evt.tagName.toLowerCase() === 'svg') {
        nextElement = evt.nextElementSibling
      } else if (evt.tagName.toLowerCase() === 'circle') {
        nextElement = evt.parentNode.nextElementSibling
      }

      nextElement.classList.remove('hidden')
    },
    editRestriction(member) {
      this.editingRestrictions = true
      this.selectedMember = member

      this.closeAllDropdown()
    },
    closeFundFamily() {
      this.fundingFamilyMember = false

      this.closeAllDropdown()
    },
    closeAllDropdown() {
      const nodes = document.querySelectorAll('.options')

      nodes.forEach((node) => {
        node.classList.add('hidden')
      })
    },
    async updateFamilySize(size) {
      try {
        if (this.loading) {
          return
        }

        this.loading = true

        const { status, data } = await this.axios.patch(
          `/families/${this.user.family.id}`,
          {
            size,
          }
        )

        if (status === 200) {
          this.$store.commit('UPDATE_FAMILY_SIZE', data.data.size)
          this.notification = {
            type: 'success',
            message: data.message,
          }
          this.loading = false
        }

        return status
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async fetchAllFamilyMembers() {
      try {
        const { status, data } = await this.axios.get(
          `/families/${this.user.family.id}/members`
        )

        if (status === 200) {
          this.familyMembers = data.data

          this.$nextTick(() => {
            stripeTable('.data-items', this.mode)
          })
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async removeMember(memberId) {
      try {
        if (this.loading) {
          return
        }

        this.loading = true

        const { status } = await this.axios.delete(
          `/families/${this.user.family.id}/members/${memberId}`
        )

        if (status === 200) {
          this.loading = false
          const member = this.familyMembers.find((tick) => tick.id === memberId)
          const familyMember = this.familyMembers

          familyMember.splice(familyMember.indexOf(member), 1)
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async transferFund() {
      try {
        this.confirmTransaction = false
        const { status, data } = await this.axios.post(
          '/account/wallet/transfer',
          this.beneficiary
        )

        if (status === 200) {
          this.transferringFunds = false

          this.notification = {
            type: 'success',
            message: data.message,
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
