<template>
  <section
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      h-full
      overflow-y-scroll
      overscroll-contain
      pb-16
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <section v-if="!selectFromAllMerchants">
      <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
        Edit Restrictions for
        <span v-if="member && member.user" class="capitalize"
          >{{ member.user.first_name }} {{ member.user.last_name }}</span
        >
      </h1>
      <div class="my-3 text-sm">Edit the transaction limit</div>

      <section class="w-full grid grid-cols-2 gap-6">
        <div>
          <div
            class="text-legend-gray dark:text-border-color capitalize w-full"
          >
            <label class="font-semibold text-sm mb-1">Transaction limit</label>
            <input
              v-model.number="member.transaction_limit"
              type="text"
              placeHolder="e.g 1000"
              class="
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                w-full
                bg-transparent
                focus:outline-none
              "
            />
          </div>
        </div>

        <div>
          <div
            class="
              text-legend-gray
              dark:text-border-color
              capitalize
              w-full
              relative
            "
            v-click-outside="hideOption"
          >
            <label class="font-semibold text-sm mb-1">Duration</label>
            <input
              v-model.number="member.duration.name"
              type="text"
              readonly
              placeHolder="Weekly"
              class="
                border border-border-color
                dark:text-border-color
                rounded-lg
                flex
                items-center
                p-3
                w-full
                bg-transparent
                focus:outline-none
              "
              @click="durationOptions = !durationOptions"
            />
            <ul
              v-if="durationOptions"
              class="
                absolute
                w-full
                top-full
                border border-gray-200
                bg-white
                dark:text-legend-black
                rounded
                text-sm
              "
            >
              <li
                v-for="(duration, index) in durationList"
                :key="index"
                class="p-2 hover:bg-gray-200 cursor-pointer capitalize"
                @click="selectOption(duration)"
              >
                {{ duration.name }}
              </li>
            </ul>
            <chevron-down-icon
              size="1.2x"
              class="custom-class absolute right-3 top-10"
              @click="durationOptions = !durationOptions"
            ></chevron-down-icon>
          </div>
        </div>
      </section>
      <custom-button @sendAction="updateTransactionLimit" class="text-sm">
        <span slot="button-text">Update Transaction Limit</span>
      </custom-button>

      <section class="mt-12">
        <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
          Merchant Restriction
        </h1>
        <div class="my-3 text-sm">
          Search and select Merchants this family member cannot visit
        </div>
      </section>
      <button
        class="
          text-primary-color
          font-medium
          block
          w-full
          rounded-md
          border border-primary-color
          py-3
          my-5
        "
        @click="selectFromAllMerchants = true"
      >
        Select from all merchants
      </button>
      <div
        v-for="(merchant, index) in restrictedMerchants"
        :key="index"
        class="
          flex
          items-start
          text-sm
          bg-primary-color bg-opacity-5
          p-3
          mb-3
          rounded-xl
        "
      >
        <div class="w-20 h-12 bg-gray-300 rounded-md flex-shrink-0"></div>
        <div class="w-full mx-6">
          <h1 class="font-semibold mb-1">{{ merchant.name }}</h1>
          <p>{{ merchant.location }}</p>
        </div>
        <div class="flex-shrink-0 flex text-primary-color cursor-pointer">
          <img src="@/assets/images/delete.svg" alt="delete" class="mr-1.5" />
          <span @click="removeMerchant(merchant.id)">Remove</span>
        </div>
      </div>
    </section>
    <section v-if="selectFromAllMerchants" class="mt-12">
      <div
        class="flex items-center my-3 text-legend-black dark:text-border-color"
      >
        <arrow-left-icon
          size="1.3x"
          class="custom-class mr-2 cursor-pointer"
          @click="selectFromAllMerchants = false"
        ></arrow-left-icon>
        <h2 class="text-xl font-medium">Select from Merchants</h2>
      </div>
      <p>Tap to select, Tap again to Unselect</p>
      <div v-if="merchants && merchants.length" class="relative">
        <input
          v-debounce:1200ms="search"
          v-model="searchTerm"
          type="search"
          placeholder="Search"
          class="
            border border-border-color
            rounded-lg
            bg-transparent
            block
            w-full
            p-2
            my-2
            pl-9
            focus:outline-none
          "
        />
        <img
          src="@/assets/images/search.svg"
          alt="search merchants"
          class="absolute left-3 top-3"
        />
      </div>
      <h2 v-if="selectedMerchants.length" class="mt-5 font-medium">
        {{ selectedMerchants.length }}
        {{ selectedMerchants.length === 1 ? 'merchant' : 'merchants' }} selected
      </h2>
      <LoadingSpinner v-if="!merchants" />
      <p
        v-if="filteredMerchants && !filteredMerchants.length"
        class="my-6 font-medium text-lg text-center"
      >
        Merchants coming soon
      </p>
      <section
        v-if="filteredMerchants && filteredMerchants.length"
        class="grid grid-cols-2 gap-7 text-sm max-h-80 mt-5 overflow-y-scroll"
      >
        <div
          v-for="(merchant, index) in filteredMerchants"
          :key="index"
          class="bg-white dark:bg-dark-mode-primary p-2 rounded-md"
          :class="{
            'border border-primary-color dark:border-border-color':
              selectedMerchants.includes(merchant),
          }"
          @click="selectMerchant(merchant)"
        >
          <div class="w-1/2 h-14 bg-gray-100 rounded-md">
            <img
              v-if="merchant && merchant.image"
              :src="`${url}/${merchant.image}`"
              :alt="merchant.name"
              class="w-full h-full rounded-md object-cover"
            />
          </div>
          <h1 class="font-bold my-1">{{ merchant.name }}</h1>
          <p>{{ merchant.location }}</p>
        </div>
      </section>
      <p
        v-if="loading && !selectedMerchants.length"
        class="text-primary-red text-lg font-medium my-6"
      >
        You must select atleast one merchant
      </p>
      <button
        class="
          bg-primary-color
          text-white
          py-2
          px-8
          rounded-md
          block
          my-12
          mx-auto
        "
        :class="{ 'inactive-state': submitting }"
        @click="done"
      >
        Done
      </button>
    </section>
  </section>
</template>

<script>
import { XIcon, ChevronDownIcon, ArrowLeftIcon } from 'vue-feather-icons'
import ClickOutside from 'vue-click-outside'
import errorModule from '@/util/error.handle'

export default {
  name: 'EditRestrictions',
  props: {
    selectedMember: {
      type: Object,
      default: null,
    },
  },
  components: { XIcon, ChevronDownIcon, ArrowLeftIcon },
  mounted() {
    this.member = this.selectedMember
    this.getAllMerchants()
    this.getRelationships()
    this.getGenders()
    this.getDurations()
    this.getRestrictedMerchants()
  },
  data() {
    return {
      member: {
        duration: { name: '' },
        transaction_limit: 0,
      },
      merchants: null,
      notification: null,
      transactionLimit: null,
      durationOptions: false,
      selectFromAllMerchants: false,
      submitting: false,
      loading: false,
      durationList: null,
      relationship: null,
      gender: null,
      selectedMerchants: [],
      restrictedMerchants: null,
      searchTerm: null,
      filteredMerchants: null,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  directives: {
    ClickOutside,
  },
  methods: {
    search() {
      if (this.searchTerm.length) {
        this.filteredMerchants = this.filteredMerchants.filter(
          (tick) =>
            tick.name.toLowerCase().includes(this.searchTerm) ||
            tick.location.toLowerCase().includes(this.searchTerm)
        )
      } else {
        this.filteredMerchants = this.merchants
      }
    },
    async updateTransactionLimit() {
      try {
        if (this.loading) {
          return
        }

        this.loading = true

        const { status } = await this.axios.patch(
          `/families/${this.member.family_id}/members/${this.member.id}`,
          {
            gender_id: this.gender.id,
            relationship_id: this.relationship.id,
            duration_id: this.member.duration.id,
            transaction_limit: this.member.transaction_limit,
          }
        )

        if (status === 200) {
          this.$emit('member-updated')
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    hideOption() {
      this.durationOptions = false
    },
    async getDurations() {
      try {
        const { status, data } = await this.axios.get(
          'http://developapi.legendpay.ng:8050/api/v1/durations'
        )

        if (status === 200) {
          const memberDuration =
            typeof this.member.duration === 'object'
              ? this.member.duration.name
              : this.member.duration
          this.durationList = data.data

          const duration = this.durationList.find(
            (tick) => tick.name.toLowerCase() === memberDuration.toLowerCase()
          )

          this.member.duration = duration
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    selectOption(duration) {
      this.member.duration = duration
      this.durationOptions = false
    },
    selectMerchant(merchant) {
      !this.selectedMerchants.includes(merchant)
        ? this.selectedMerchants.push(merchant)
        : this.selectedMerchants.splice(
            this.selectedMerchants.indexOf(merchant),
            1
          )
    },
    async getAllMerchants() {
      try {
        const { status, data } = await this.axios.get(
          'account/products/branches'
        )

        if (status === 200) {
          this.merchants = data.data.branches
          this.filteredMerchants = this.merchants
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async getRelationships() {
      try {
        const { status, data } = await this.axios.get(
          'http://developapi.legendpay.ng:8050/api/v1/relationships'
        )

        if (status === 200) {
          const relationship = data.data.find(
            (tick) =>
              tick.name.toLowerCase() === this.member.relationship.toLowerCase()
          )
          this.relationship = relationship
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async getGenders() {
      try {
        const { status, data } = await this.axios.get(
          'http://developapi.legendpay.ng:8050/api/v1/genders'
        )

        if (status === 200) {
          const gender = data.data.find(
            (tick) =>
              tick.name.toLowerCase() === this.member.user.gender.toLowerCase()
          )

          this.gender = gender
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async getRestrictedMerchants() {
      try {
        const { status, data } = await this.axios.get(
          `/families/${this.member.family_id}/members/${this.member.id}/restricted-merchants`
        )

        if (status === 200) {
          this.restrictedMerchants = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async done() {
      try {
        const payload = this.selectedMerchants.map((merchant) => {
          return { merchant_id: merchant.id }
        })

        if (this.submitting) {
          return
        }

        this.loading = true

        if (!this.selectedMerchants.length) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post(
          `/families/${this.member.family_id}/members/${this.member.id}/restricted-merchants`,
          { merchants: payload }
        )

        if (status === 200) {
          this.$emit('merchant-restricted', data.message)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async removeMerchant(merchantId) {
      try {
        if (this.loading) {
          return
        }

        this.loading = true

        const { status, data } = await this.axios.delete(
          `/families/${this.member.family_id}/members/${this.member.id}/restricted-merchants/${merchantId}`
        )

        if (status === 200) {
          this.loading = false
          this.notification = {
            type: 'success',
            message: data.message,
          }

          const merchant = this.restrictedMerchants.find(
            (tick) => tick.id === merchantId
          )
          const restricted = this.restrictedMerchants

          restricted.splice(restricted.indexOf(merchant), 1)
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
