<template>
  <main
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      h-screen
      overflow-y-scroll
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section v-if="!memberAdded" class="pb-24">
      <x-icon
        size="1.3x"
        class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
        @click="$emit('closeModal')"
      ></x-icon>
      <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
        Add New Family Member
      </h1>
      <div class="my-3 text-sm">Update family list</div>
      <div
        class="
          switch-container
          rounded-lg
          flex
          items-center
          p-1
          my-8
          font-semibold
          text-sm text-legend-gray
          dark:text-border-color
          text-center
          uppercase
          bg-primary-color bg-opacity-10
        "
        @click.stop="isLegendCustomer = !isLegendCustomer"
      >
        <div
          class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
          :class="{ 'bg-white text-primary-color': isLegendCustomer }"
        >
          legend customer
        </div>
        <div
          class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
          :class="{ 'bg-white text-primary-color': !isLegendCustomer }"
        >
          new customer
        </div>
      </div>

      <div class="mb-6 relative">
        <div
          class="
            font-semibold
            text-sm
            mb-1
            capitalize
            text-legend-gray
            dark:text-border-color
          "
        >
          username
        </div>
        <input
          v-debounce:1000="validateUsername"
          v-model="username"
          type="text"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
        <div
          v-if="validatingUser"
          class="p-1 text-sm text-secondary-green italic"
        >
          Verifying Username...
        </div>
        <div class="flex justify-between text-sm p-1">
          <span v-if="userNoFound" class="text-primary-red italic"
            >Username does not exist</span
          >
          <!-- <router-link :to="{ name: 'Register' }" class="font-medium"
            >Create new account?</router-link
          > -->
        </div>
        <ValidationMessage
          v-if="loading && !isLegendCustomer && !username"
          class="-mt-2"
        >
          <span slot="message">Username is required</span>
        </ValidationMessage>
      </div>

      <section :class="{ hidden: isLegendCustomer && !firstName }">
        <div class="mb-6 relative">
          <div
            class="
              font-semibold
              text-sm
              mb-1
              capitalize
              text-legend-gray
              dark:text-border-color
            "
          >
            Email Address
          </div>
          <input
            v-model="email"
            type="text"
            class="
              w-full
              border border-border-color
              rounded-lg
              flex
              items-center
              p-3
              bg-transparent
              focus:outline-none
            "
          />
          <ValidationMessage v-if="loading && !$v.email.required" class="-mt-1">
            <span slot="message">Email is required</span>
          </ValidationMessage>
          <ValidationMessage v-if="loading && !$v.email.email">
            <span slot="message">Enter a valid email address.</span>
          </ValidationMessage>
        </div>

        <div class="grid grid-cols-2 gap-6">
          <div class="mb-6 relative">
            <div
              class="
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
              "
            >
              first name
            </div>
            <input
              v-model="firstName"
              type="text"
              class="
                w-full
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                bg-transparent
                focus:outline-none
              "
            />
            <ValidationMessage
              v-if="loading && !$v.firstName.required"
              class="-mt-1"
            >
              <span slot="message">First name is required</span>
            </ValidationMessage>
          </div>
          <div class="mb-6 relative">
            <div
              class="
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
              "
            >
              last name
            </div>
            <input
              v-model="lastName"
              type="text"
              class="
                w-full
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                bg-transparent
                focus:outline-none
              "
            />
            <ValidationMessage
              v-if="loading && !$v.lastName.required"
              class="-mt-1"
            >
              <span slot="message">Last name is required</span>
            </ValidationMessage>
          </div>
        </div>
        <div class="flex items-center relative">
          <div class="w-28 flex-shrink-0 relative">
            <div class="w-full relative">
              <label
                class="
                  text-legend-gray
                  dark:text-border-color
                  font-semibold
                  block
                  leading-relaxed
                "
                >Country code</label
              >
              <div
                class="
                  flex
                  items-center
                  w-full
                  border border-border-color
                  rounded-lg
                  py-3
                  px-1
                "
              >
                <img
                  :src="selectedCountryFlag"
                  alt=""
                  class="w-8 rounded shadow-md"
                />
                <vue-autosuggest
                  v-model="query"
                  :suggestions="filteredOptions"
                  @click="clickHandler"
                  @selected="onSelected"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: '+234',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <img
                      :style="{
                        display: 'flex',
                        width: '25px',
                        height: '25px',
                        borderRadius: '15px',
                        marginRight: '10px',
                      }"
                      :src="suggestion.item.href.flag"
                    />
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.name }}
                    </div>
                  </div>
                </vue-autosuggest>
              </div>
            </div>
          </div>
          <div class="w-full lg:ml-6 xl:ml-6 md:ml-5 ml-3">
            <label
              class="
                text-legend-gray
                dark:text-border-color
                font-semibold
                block
                leading-relaxed
              "
              >Phone number</label
            >
            <input
              v-model.number.trim="phoneNumber"
              placeholder="e.g 8160973197"
              class="
                text-seconadry-border
                bg-transparent
                border border-border-color
                rounded-lg
                w-full
                p-3
                focus:outline-none
              "
            />
          </div>

          <ValidationMessage
            v-if="loading && !$v.phoneNumber.required"
            class="absolute left-40 -bottom-8"
          >
            <span slot="message">Phone number is required.</span>
          </ValidationMessage>
        </div>
        <div class="grid grid-cols-2 gap-6 mt-6">
          <div class="mb-6 mt-1 relative w-full">
            <div
              class="
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
              "
            >
              date of birth
            </div>
            <date-picker
              v-model="dob"
              format="DD-MM-YYYY"
              type="date"
              placeholder="Select date"
              style="background: transparent"
            ></date-picker>
            <ValidationMessage v-if="loading && !$v.dob.required" class="-mt-1">
              <span slot="message">Date of birth is required</span>
            </ValidationMessage>
          </div>
          <div class="mb-6 relative">
            <div
              class="
                gender-options
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
                relative
              "
            >
              gender
            </div>
            <input
              v-model="gender.name"
              type="text"
              readonly
              class="
                gender-options
                w-full
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                bg-transparent
                focus:outline-none
                capitalize
              "
              @click.self="getGenders"
            />
            <ul
              v-click-outside="hideDropdown"
              v-if="genderOptions"
              class="
                border border-gray-200
                rounded-md
                bg-white
                dark:text-legend-gray
                absolute
                top-full
                w-full
                z-50
                shadow-md
              "
            >
              <li
                v-for="(gender, index) in genderList"
                :key="index"
                class="py-2 px-3 hover:bg-gray-200 cursor-pointer capitalize"
                @click="selectGender(gender)"
              >
                {{ gender.name }}
              </li>
            </ul>
            <chevron-down-icon
              size="1.2x"
              class="custom-class absolute right-4 top-10"
              @click.self="getGenders"
            ></chevron-down-icon>
            <ValidationMessage
              v-if="loading && !$v.gender.required"
              class="-mt-1"
            >
              <span slot="message">Gender is required</span>
            </ValidationMessage>
          </div>
        </div>

        <div class="mb-6 relative">
          <div
            class="
              relationship-options
              font-semibold
              text-sm
              mb-1
              capitalize
              text-legend-gray
              dark:text-border-color
            "
          >
            Relationship
          </div>
          <input
            v-model="relationship.name"
            type="text"
            readonly
            class="
              relationship-options
              w-full
              border border-border-color
              rounded-lg
              flex
              items-center
              p-3
              bg-transparent
              focus:outline-none
              capitalize
            "
            @click="getRelationships"
          />
          <ul
            v-if="relationshipOptions"
            v-click-outside="hideDropdown"
            class="
              border border-gray-200
              rounded-md
              bg-white
              dark:text-legend-gray
              absolute
              top-full
              w-full
              h-40
              overflow-y-scroll
              overscroll-y-contain
              z-50
              shadow-md
            "
          >
            <li
              v-for="(relationship, index) in relationshipList"
              :key="index"
              class="py-2 px-3 hover:bg-gray-200 cursor-pointer capitalize"
              @click="selectRelationship(relationship)"
            >
              {{ relationship.name }}
            </li>
          </ul>
          <chevron-down-icon
            size="1.2x"
            class="custom-class absolute right-4 top-10"
            @click="getRelationships"
          ></chevron-down-icon>
          <ValidationMessage
            v-if="loading && !$v.relationship.required"
            class="-mt-1"
          >
            <span slot="message">Select relationship</span>
          </ValidationMessage>
        </div>

        <div class="grid grid-cols-2 gap-6">
          <div class="mb-6 relative">
            <div
              class="
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
              "
            >
              Transaction limit
            </div>
            <input
              v-model="transactionLimit"
              type="text"
              class="
                w-full
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                bg-transparent
                focus:outline-none
                pl-8
              "
            />
            <span
              class="
                custom-class
                absolute
                left-4
                top-9
                text-lg
                font-semibold
                text-primary-color
              "
              >₦</span
            >
            <ValidationMessage
              v-if="loading && !$v.transactionLimit.required"
              class="-mt-1"
            >
              <span slot="message">Enter transaction limit</span>
            </ValidationMessage>
          </div>
          <div class="mb-6 relative">
            <div
              class="
                duration-options
                font-semibold
                text-sm
                mb-1
                capitalize
                text-legend-gray
                dark:text-border-color
              "
            >
              Duration
            </div>
            <input
              v-model="duration.name"
              type="text"
              readonly
              class="
                duration-options
                w-full
                border border-border-color
                rounded-lg
                flex
                items-center
                p-3
                bg-transparent
                focus:outline-none
                pr-8
              "
              @click="getDurations"
            />
            <ul
              v-if="durationOptions"
              v-click-outside="hideDropdown"
              class="
                border border-gray-200
                rounded-md
                bg-white
                dark:text-legend-gray
                absolute
                top-full
                w-full
                z-50
                shadow-md
              "
            >
              <li
                v-for="(duration, index) in durationList"
                :key="index"
                class="py-2 px-3 hover:bg-gray-200 cursor-pointer capitalize"
                @click="selectDuration(duration)"
              >
                {{ duration.name }}
              </li>
            </ul>
            <chevron-down-icon
              size="1.2x"
              class="custom-class absolute right-4 top-10"
              @click="getDurations"
            ></chevron-down-icon>
            <ValidationMessage
              v-if="loading && !$v.duration.required"
              class="-mt-1"
            >
              <span slot="message">Select duration</span>
            </ValidationMessage>
          </div>
        </div>
        <custom-button :loading="submitting" @sendAction="addMember">
          <span slot="button-text">Add new Member</span>
        </custom-button>
      </section>
    </section>
    <section
      v-if="memberAdded"
      class="h-screen flex flex-col items-center justify-center"
    >
      <img
        src="@/assets/images/success-icon.svg"
        alt="member add successfully"
      />
      <h1 class="text-2xl text-primary-color font-medium my-3">
        New Family Member Added
      </h1>
      <p class="text-sm">You Successfully added a new family member.</p>
      <button
        class="
          bg-transparent
          border border-primary-color
          text-primary-color
          font-medium
          rounded-lg
          py-3
          px-28
          mt-6
        "
        @click="$emit('closeModal')"
      >
        Close
      </button>
    </section>
  </main>
</template>

<script>
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import { required, email } from 'vuelidate/lib/validators'
import ClickOutside from 'vue-click-outside'
import { VueAutosuggest } from 'vue-autosuggest'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import listOfCountries from '@/util/countries'
import errorModule from '@/util/error.handle'

export default {
  name: 'AddNewFamilyMember',
  components: {
    XIcon,
    ChevronDownIcon,
    DatePicker,
    VueAutosuggest,
  },
  mounted() {
    this.getInputField()
  },
  data() {
    return {
      loading: false,
      submitting: false,
      notification: null,
      memberAdded: false,
      isLegendCustomer: true,
      username: null,
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      dob: null,
      transactionLimit: null,
      duration: {
        id: '',
        name: '',
      },
      durationOptions: false,
      gender: {
        id: '',
        name: '',
      },
      genderList: null,
      genderOptions: false,
      relationship: {
        id: '',
        name: '',
      },
      relationshipOptions: false,
      relationshipList: null,
      durationList: null,
      validatingUser: false,
      userNoFound: false,
      suggestions: listOfCountries,
      query: '',
      selected: '+234',
      selectedCountryFlag:
        'https://restfulcountries.com/assets/images/flags/Nigeria.png',
      baseURL:
        process.env.NODE_ENV === 'development'
          ? 'http://developapi.legendpay.ng:8050/api/v1'
          : 'https://api.legendpay.ng/api/v1',
    }
  },
  validations() {
    return {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phoneNumber: { required },
      dob: { required },
      duration: { required },
      transactionLimit: { required },
      gender: { required },
      relationship: { required },
    }
  },
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapGetters(['mode', 'user']),
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter((option) => {
            return (
              option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
          }),
        },
      ]
    },
  },
  methods: {
    getInputField() {
      const node = document.querySelector('.mx-input')
      node.style.color = this.mode === 'dark' ? '#B9B9BB' : '#262324'
    },
    clickHandler() {
      this.selected = null
    },
    onSelected(item) {
      this.selected = item.item.phone_code
      this.selectedCountryFlag = item.item.href.flag
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.phone_code.charAt(0) === '+'
        ? suggestion.item.phone_code
        : `+${suggestion.item.phone_code}`
    },
    async validateUsername() {
      try {
        if (!this.isLegendCustomer) {
          return
        }

        this.userNoFound = false

        if (!this.username || (this.username && !this.username.length)) {
          return
        }

        this.validatingUser = true
        const { status, data } = await this.axios.post('/account/username', {
          username: this.username,
        })

        if (status === 200) {
          this.validatingUser = false

          this.firstName = data.data.firstname
          this.lastName = data.data.lastname
          this.email = data.data.email
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        } else if (error.response.status === 422) {
          this.firstName = null
          this.lastName = null
          this.email = null
          this.userNoFound = true
          this.notification = {
            type: 'error',
            message: error.response.data.errors.username[0],
          }
        }
        this.validatingUser = false
        this.full_name = null
      }
    },
    hideDropdown(event) {
      const classList = Array.from(event.target.classList)

      if (!classList.includes('gender-options')) {
        this.genderOptions = false
      }

      if (!classList.includes('relationship-options')) {
        this.relationshipOptions = false
      }

      if (!classList.includes('duration-options')) {
        this.durationOptions = false
      }
    },
    async getGenders() {
      try {
        const { status, data } = await this.axios.get(`${this.baseURL}/genders`)

        if (status === 200) {
          this.genderList = data.data
          localStorage.setItem('genderlist', JSON.stringify(data.data))
          this.genderOptions = true
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    selectGender(gender) {
      this.gender = gender
      this.genderOptions = false
    },
    async getRelationships() {
      try {
        const { status, data } = await this.axios.get(
          `${this.baseURL}/relationships`
        )

        if (status === 200) {
          this.relationshipList = data.data

          this.relationshipOptions = true
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    selectRelationship(relationship) {
      this.relationship = relationship
      this.relationshipOptions = false
    },
    async getDurations() {
      try {
        const { status, data } = await this.axios.get(
          `${this.baseURL}/durations`
        )

        if (status === 200) {
          this.durationList = data.data
          this.durationOptions = true
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    selectDuration(duration) {
      this.duration = duration
      this.durationOptions = false
    },
    async addMember() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        if (this.loading && !this.isLegendCustomer && !this.username) {
          return
        }

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status } = await this.axios.post(
          `/families/${this.user.family.id}/members`,
          {
            username: this.username,
            email_address: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            country_code: this.selected,
            phone_number: this.phoneNumber,
            dob: this.dob,
            gender_id: this.gender.id,
            relationship_id: this.relationship.id,
            transaction_limit: this.transactionLimit,
            duration_id: this.duration.id,
          }
        )
        if (status >= 200 && status < 300) {
          this.memberAdded = true
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }

          this.submitting = false
        } else {
          this.notification = errorModule(
            error,
            this.notification,
            this.$router,
            this.$store
          )
        }
      }
    },
  },
}
</script>

<style scoped>
.switch-container {
  width: 20rem;
}

.light-mode-bg {
  background-color: rgba(253, 79, 0, 0.1);
}

.dark-mode-bg {
  background-color: #1c1c1c;
}

@media only screen and (max-width: 560px) {
  .switch-container {
    width: 100%;
  }

  .switch-container > div {
    font-size: 0.7rem;
  }
}
</style>
