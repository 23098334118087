<template>
  <section
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      h-full
      overflow-y-scroll
      overscroll-contain
      pb-16
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Transfer Funds
    </h1>
    <div class="my-3 text-sm">Send funds to your Family Members</div>
    <p class="my-8 font-semibold capitalize">
      Transfer funds to {{ beneficiary.user.first_name }}
      {{ beneficiary.user.last_name }}
    </p>

    <div class="relative mt-6">
      <label class="font-semibold text-sm mb-1 capitalize">amount</label>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model.lazy="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage v-if="loading && !amount">
        <span slot="message">Amount is required</span>
      </ValidationMessage>
    </div>
    <div class="relative mt-6">
      <label
        class="
          text-legend-gray
          dark:text-border-color
          font-semibold
          block
          leading-relaxed
        "
        >Transaction Pin</label
      >
      <input
        v-model="pin"
        :type="inputType"
        placeholder="e.g 0000"
        class="
          text-seconadry-border
          bg-transparent
          border border-border-color
          rounded-lg
          w-full
          p-3
          focus:outline-none
        "
      />
      <img
        :src="imageURL"
        alt="toggle password visibility"
        class="absolute right-6 top-11 w-7 cursor-pointer"
        @click="togglePasswordField"
      />
      <ValidationMessage v-if="loading && !$v.pin.required">
        <span slot="message">Transaction pin is required</span>
      </ValidationMessage>
    </div>
    <custom-button @sendAction="send" class="text-sm">
      <span slot="button-text">Send</span>
    </custom-button>
  </section>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'TransferFundsToFamily',
  components: { XIcon },
  props: {
    beneficiary: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      amount: 0,
      notification: null,
      pin: null,
      inputType: 'password',
      loading: false,
    }
  },
  validations() {
    return {
      pin: { required },
    }
  },
  computed: {
    imageURL() {
      let image

      this.inputType === 'password'
        ? (image = 'eye-icon.svg')
        : (image = 'eye-icon-active.svg')

      return this.getImgUrl(image)
    },
  },
  methods: {
    getImgUrl(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    togglePasswordField() {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    send() {
      this.loading = true

      if (this.loading && !this.amount) {
        return
      }

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('confirmAction', {
        username: this.beneficiary.user.username,
        amount: this.amount,
        full_name: `${this.beneficiary.user.first_name} ${this.beneficiary.user.last_name}`,
        pin: this.pin,
      })
    },
  },
}
</script>

<style></style>
